<!-- 
	This is the basic sign up page, it uses the default layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5"></h1>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Card -->
		<a-card :bordered="false" class="card-signup header-solid" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h5 class="font-semibold text-center">Log In</h5>
			</template>

			<!-- Sign Up Form -->
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'id',
						{ rules: [{ required: true, message: 'Please input your id!' }] },
						]"
						placeholder="Id"
					>
					</a-input>
				</a-form-item>
				
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] },
						]"
						type="password"
						placeholder="Password"
					>
					</a-input>
				</a-form-item>
				
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						LOGIN
					</a-button>
				</a-form-item>
			</a-form>
			<!-- / Sign Up Form -->

		</a-card>
		<!-- / Sign Up Card -->

	</div>
</template>

<script>

	export default ({
		data() {
			return {
				// Sign up form object.
				form: this.$form.createForm(this, { name: 'signup_basic' }),
			}
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;
					}
					this.$store
						.dispatch("login", {
							email: values.id,
							password: values.password,
						})
						.then((err) => {
							// console.log(err)
							this.redirect();
						});

				});
			},
			redirect() {
				this.$router.push("/");
			},
		},
	})

</script>

<style lang="scss">
</style>